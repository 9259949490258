import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Informations from "../components/informationComponents/Informations"
import ContactForm from "../components/formComponents/ContactForm"
import StyledTitle from "../components/StyledTitle"
import GImage from "../components/GImage"
import Seo from "../components/seo"
import Footer from "../components/Footer"
import BaseContext from "../context/baseContext"
// import GoogleMaps from "../components/GoogleMaps"

const InsetBoxShadow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 10px 200px 30px rgba(0, 0, 0, 0.6) inset;
  -webkit-box-shadow: 0px 10px 200px 30px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0px 10px 200px 30px rgba(0, 0, 0, 0.6) inset;
  @media (min-width: 600px) {
    box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.8) inset;
    -webkit-box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.8) inset;
    -moz-box-shadow: 0px -40px 160px 50px rgba(0, 0, 0, 0.8) inset;
  }
`

const Contact = ({ data: gqlData }) => {
  const { directus, drone2, cave, bouchon } = gqlData
  const headerRef = React.useContext(BaseContext)

  const params = {
    contactForm: {
      defaultSubject: "visiter",
      title: {
        text: "Contactez-nous",
        variant: "h3",
        component: "h3",
      },
    },
    mentionsLegales: {
      ariaLabel: "mentions-legales",
      title: "Informations légales",
      content: "",
    },
  }

  return (
    <>
      <Seo
        title="Contactez-nous - Ralph Petit et David Theunis, vignerons récoltants"
        description="Contactez le Vignoble de Jape-Loup. Vente direct au caveau. 231 Chemin du Cardinal 30130 Saint-Alexandre"
      />
      <section className="relative" ref={headerRef}>
        <InsetBoxShadow />
        <GImage
          objectPosition="50% 10%"
          objectFit="cover"
          className="h-60"
          imgClassName="h-60"
          src={drone2}
          alt={`Photo du vignoble de Jape-loup de la page contact`}
        />
      </section>
      <div className="py-8 px-6 grid grid-cols-1 lg:grid-cols-5 grid-flow-row auto-rows-min gap-8 max-w-screen-2xl mx-auto">
        <div className="relative overflow-hidden lg:col-span-2 mx-auto lg:mx-0">
          <div className="px-8 sm:px-20 pt-20 pb-8 bg-stone-50 border-2 border-stone-100 relative mx-auto mb-8 flex justify-center w-full">
            <div className="inline-block">
              <Informations darkTheme={false} />
            </div>
          </div>
          <GImage
            src={bouchon}
            style={{ display: "block" }}
            objectPosition="100% -100px"
            objectFit="cover"
            className="w-full block h-auto"
          />
        </div>
        <div className="lg:col-span-3">
          <div className="mx-auto max-w-c_medium relative">
            <div className="px-8 sm:px-20 pt-20 pb-6 bg-stone-50 border-2 border-stone-100">
              <StyledTitle
                text={params.contactForm.title.text}
                component="h3"
                className="h1-variant font-bold"
                darkTheme={false}
              />
              <ContactForm defaultSubject={params.contactForm.defaultSubject} />
            </div>
            <GImage
              src={cave}
              style={{ display: "block" }}
              className="w-full block h-96 ml-auto mr-0 mt-8"
            />
          </div>
        </div>
      </div>
      {/* <div className="shadow-lg">
        <GImage
          objectPosition="center center"
          objectFit="cover"
          className="h-50vh"
          imgClassName="h-50vh"
          src={gmap}
          alt={`Photo de la carte google map du vignoble`}
        />
      </div> */}

      {/* <div>
        <GoogleMaps />
      </div> */}
      {directus.pages_settings.mentions && (
        <Footer mentions={directus.pages_settings.mentions} />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query {
    # locales: allLocale(filter: { language: { eq: $language } }) {
    #   edges {
    #     node {
    #       ns
    #       data
    #       language
    #     }
    #   }
    # }
    directus: allDirectus {
      pages_settings {
        mentions
      }
    }
    drone2: file(relativePath: { eq: "diaporama/45.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    cave: file(relativePath: { eq: "les-vins/cave.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
    bouchon: file(relativePath: { eq: "les-vins/bouchon2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
  }
`
export default Contact
